const data = [
  {
    coverImage: "joan_punyet_miro.jpg",
    coverText: "joan_punyet_miro.svg",
    menu: [
      { title: "Influenza della natura nelle sue opere", video: "9.mp4" },
      { title: "Simbiosi tra il ciclo pittorico e la natura", video: "3.mp4" },
      { title: "Simbiosi tra le sue sculture e la natura", video: "4.mp4" },
      { title: "Miro ecologista", video: "6.mp4" },
      { title: "Distruzione del paesaggio e il boom immobiliare", video: "8.mp4" }
    ]
  },
  {
    coverImage: "rosa_maria_malet.jpg",
    coverText: "rosa_maria_malet.svg",
    menu: [
      { title: "Option 1", video: "video.mp4" }
    ]
  },
  {
    coverImage: "joan_baixas.jpg",
    coverText: "joan_baixas.svg",
    menu: [
      { title: "Option 1", video: "video.mp4" }
    ]
  },
  {
    coverImage: "isabel_steva_colita.jpg",
    coverText: "isabel_steva_colita.svg",
    menu: [
      { title: "Option 1", video: "video.mp4" }
    ]
  }
]

// 0: Joan Punyet Miro
// 1: Rosa Maria Malet
// 2: Joan Baixas
// 3: Isabel Steva Colita
export const DATA = data[0];